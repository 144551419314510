import React from "react"
import { ApolloProvider } from "@apollo/react-hooks"
import { client } from "./src/apollo/client"
import PopUpProvider from "./src/context/PopUpContext"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <PopUpProvider>{element}</PopUpProvider>
  </ApolloProvider>
)
