// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-pagination-js": () => import("./../../../src/pages/pagination.js" /* webpackChunkName: "component---src-pages-pagination-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-casestudy-js": () => import("./../../../src/templates/casestudy.js" /* webpackChunkName: "component---src-templates-casestudy-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-our-team-js": () => import("./../../../src/templates/our-team.js" /* webpackChunkName: "component---src-templates-our-team-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-stories-js": () => import("./../../../src/templates/stories.js" /* webpackChunkName: "component---src-templates-stories-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

